import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-basic-card',
  templateUrl: './icon-basic-card.component.html',
  styleUrl: './icon-basic-card.component.scss'
})
export class IconBasicCardComponent {
  @Input() headerTitle: string = '';
  @Input() icon: string = '';
  @Input() singleContent: any = 'Lorem impusm dolor castro pefit stis ';
  isArray(value: any): value is string[] {
    return Array.isArray(value);
  }
}
