<div *ngIf="columns.length" [ngClass]="customClass">
  <div *ngIf="title" class="container__subsection">
    <h3 class="title__subsection">{{ title }}</h3>
  </div>
  <p-table
    [columns]="columns"
    [value]="dataSource"
    [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          id="{{ column.name }}"
          class="table__header"
          *ngFor="let column of columns; index as i"
          [ngStyle]="{'display': column.display}"
        >
          {{ column.label }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          class="table__body"
          *ngFor="let column of columns"
          [ngSwitch]="column.cellType"
          [ngStyle]="{'display': column.display}"
        >
          <p class="table__item" *ngSwitchCase="'currency'">
            {{ rowData[column.name] | currency : "$" : "symbol" : "1.0-0" }}
          </p>
          <p class="table__item" *ngSwitchCase="'yesorno'">
            {{ rowData[column.name] ? "SI" : "NO" }}
          </p>
          <p class="table__item" *ngSwitchDefault>{{ rowData[column.name] }}</p>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p class="table__footer">{{tableFooterMsn}}</p>
</div>
