import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
// Importa la directiva correctamente
import { DateInputRestrictionDirective } from './directives/date-input-restriction-directive.directive';
import { ModalComponent } from './components/modal/modal.component';
import { HeaderComponent } from './components/header/header.component';
import { PrimeNgModule } from './prime-ng/prime-ng.module';
import { BasicTableComponent } from './components/tables/basic-table/basic-table.component';
import { IconBasicCardComponent } from './components/cards/icon-basic-card/icon-basic-card.component';
import { ModalMessageComponent } from './components/modals/modal-message/modal-message.component';
import { ActionValidateDirective } from './directives/action-validate.directive';

@NgModule({
  declarations: [
    NavbarComponent,
    SpinnerComponent,
    DateInputRestrictionDirective,
    ModalComponent,
    HeaderComponent,
    BasicTableComponent,
    IconBasicCardComponent,
    ModalMessageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PrimeNgModule,
    ActionValidateDirective
  ],
  exports:[
    ReactiveFormsModule,
    NavbarComponent,
    SpinnerComponent,
    DateInputRestrictionDirective,
    ModalComponent,
    HeaderComponent,
    BasicTableComponent,
    IconBasicCardComponent,
    PrimeNgModule,
    ModalMessageComponent,

  ],
  providers:[
    AuthService
  ]
})
export class SharedModule { }
