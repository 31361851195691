import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { MENU_OPTIONS } from '../../constants/menu.const';
import { AuthService } from 'app/shared/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from 'app/pichincha-libranza-operativa/modules/sales-reassignment/components/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  @Input() openSidebar: boolean = false;
  @Input() userName: string = '';
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() logout: EventEmitter<boolean> = new EventEmitter<boolean>();
  navItems = MENU_OPTIONS;

  constructor(
    private router: Router,
    private authService: AuthService,
    private _matDg: MatDialog) {}

  clickMenu(type: any) {
    if (type.title === 'Cerrar sesión') {
      this._matDg.open(ModalConfirmationComponent,{
        data: {
          title: '¿Estás seguro/a de que quieres salir?'
        }
      }).afterClosed().subscribe(
        ({isConfirmed}: any) => isConfirmed && this.closeSession(type) );
    } else {
      this.closed();
      this.router.navigate([type.url]);
    }
  }

  closeSession(type: any){
    this.router.navigate([type.url]);
    this.authService.logout();
  }

  closed() {
    this.close.emit(false);
  }

  toggleSubMenu(item: any) {
    if(!item.subMenuOpen){
      this.navItems.forEach( el => el.subMenuOpen = false );
    }
    item.subMenuOpen = !item.subMenuOpen;
  }

}
