<aside [ngClass]="{'aside': true, 'aside-open': openSidebar }">
  <section class="aside__content">
    <div class="flex aside__header">
      <img class="logo" src="./assets/img/logo_pichincha.png" alt="Logo Banco Pichincha" />
      <button class="btn-dowm" (click)="openSidebar = !openSidebar" (click)="closed()">
        <img src="/assets/img/x.svg" alt="Close">
      </button>
    </div>
    <div class="flex aside__user">
      <div class="flex container">
        <img src="./assets/img/usuario-icono.png" alt="">
        <button class="btn-dowm">
          <i class="fas fa-chevron-down ml-auto"></i>
        </button>
        <p class="text-start name">{{userName}}</p>
      </div>
    </div>

    <nav class="aside__nav">
      @for (item of navItems; track $index) {
        <li class="item" [ngClass]="{ 'item-open': item.subMenuOpen }" (click)=" item.subMenuItems.length === 0 ? clickMenu(item) : toggleSubMenu(item)">
          <div class="item__cont">
            @if (item.img !== '') {
              <img class="" [src]="item?.img"
                   [alt]="item.title" style="width: 30px; height: 30px; object-fit: contain;" />
            }
            <div class="flex-grow-1">
              <h4 class="mb-0 text-left title">{{item.title}}</h4>
              @if (item.info != '') {
                <p class="mb-0 text-left info">{{item.info}}</p>
              }
            </div>
            @if (item.subMenuItems.length > 0) {
              <button class="btn-dowm">
                <i class="fas fa-chevron-down ml-auto"></i>
              </button>
            }
          </div>
        </li>
          <div class="childrens"
               [ngStyle]="{ 'height': item.subMenuOpen ? ((item?.subMenuItems?.length ?? 0) * 50)+'px': '0px' }">
            @for (subItem of item?.subMenuItems; track subItem.title) {
              <div class="item item-subitem" (click)="clickMenu(subItem)">
                    <h4 class="mb-0 subtitle"  >{{subItem.title}}</h4>
              </div>
            }
          </div>
      }
    </nav>
  </section>
  <div [ngClass]="{'aside__bg': true, 'aside__bg-open': openSidebar }" (click)="closed()"></div>
</aside>
