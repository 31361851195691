import { Component } from '@angular/core';
import { SpinnerService } from '../../services/shared-service/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  isLoading;

  constructor(private readonly spinnerService: SpinnerService) {
    this.isLoading = this.spinnerService.isLoading;
  }
}
