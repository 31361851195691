<p-card header="{{ headerTitle }}">
    <ng-template pTemplate="header">
      <img [src]="icon" alt="Header Image" />
    </ng-template>
    <ng-container *ngIf="isArray(singleContent); else singleContentBlock">
      <ul>
        <li *ngFor="let item of singleContent">
          {{ item }}
        </li>
      </ul>
    </ng-container>
    <ng-template #singleContentBlock>
      <p class="m-0">{{ singleContent }}</p>
    </ng-template>
  </p-card>
