<header class="header">
    <div class="header__container">
        <div class="row align-items-center">
            <div class="col-6 col-sm-2">
              <img *ngIf="!isProductRoute" src="./assets/img/menu-icon.svg" (click)="collapseNav()" alt="Hamburguesa" class="hamburger-icon">
            </div>
            <div class="header__right col-6 col-sm-10 text-right ">
                <h2 class="subtitle">Gestion Comercial</h2>
                <span class="separator"></span>
                <img class="logotipo-header d-sm-inline d-md-inline d-lg-inline" src="./assets/img/logo_pichincha.png"
                    alt="Imagen del Logotipo" />
            </div>
        </div>
    </div>
</header>
<app-navbar
    *ngIf="userDisplayName"
    [openSidebar]="collapse"
    [userName]="userDisplayName"
    (close)="collapseNav()" />
