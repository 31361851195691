import { NgModule } from '@angular/core';

import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

const primeModules = [
  TableModule,
  FloatLabelModule,
  InputTextareaModule,
  InputGroupModule,
  InputGroupAddonModule,
  InputTextModule,
  InputNumberModule,
  CheckboxModule,
  AccordionModule,
  PanelModule,
  CardModule,
  DialogModule,
  ButtonModule,
];

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    ...primeModules
  ]
})
export class PrimeNgModule { }
