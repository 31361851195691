import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrl: './basic-table.component.scss'
})
export class BasicTableComponent {

  @Input() customClass: string = '';
  @Input() title = '';
  @Input() columns: any[] = [];
  @Input() dataSource: any[] = [];
  @Input() tableFooterMsn: string = ''

}
