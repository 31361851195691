import { Component, inject, Input } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() userDisplayName: string | null = null;
  isProductRoute: boolean = true;
  private _router = inject(Router);
  collapse: boolean = false;
  private routerSubscription: Subscription | null = null;

  ngOnInit() {
    this.isProductRoute = this._router.url.includes('/product');

    this.routerSubscription = this._router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.isProductRoute = event.url.includes('/product');
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  collapseNav() {
    this.collapse = !this.collapse;
  }
}
