export const MENU_OPTIONS = [
  {
    img: './assets/img/menu-home.png',
    title: 'Inicio',
    info: 'Encuentra las características de  Crédito de Libranza y Convenios',
    url: '/welcome',
    subMenuOpen: false,
    subMenuItems: [],
  },
  // {
  //   img: './assets/img/crear-venta.png',
  //   title: 'Crear venta prospecto',
  //   info: 'Registra aquí nuevos solicitantes',
  //   url: '/crear-venta-prospecto',
  //   subMenuOpen: false,
  //   subMenuItems: [],
  // },
  {
    img: './assets/img/prospecto.png',
    title: 'Gestión de venta prospecto',
    info: 'Consulta el estado de tus solicitudes de prospectos',
    url: '/gestion-de-casos',
    subMenuOpen: false,
    subMenuItems: [],
  },
  {
    img: './assets/img/menu-simulador.png',
    title: 'Simulador de cuota',
    info: 'Simula las condiciones y cuotas para una Libranza',
    subMenuOpen: false,
    subMenuItems: [
      {
        title: 'Simulación cuota máxima básica',
        url: '/simulacion/libranza',
      },
      {
        title: 'Simulación cuota máxima integral',
        url: '/simulacion/cuota-maxima-integral',
      },
    ],
  },
  // {
  //   img: './assets/img/menu-perfilamiento.png',
  //   title: 'Perfilamiento de solicitudes',
  //   info: 'Completa el proceso de tu prospecto y confirma la solicitud del crédito',
  //   url: '',
  //   subMenuOpen: false,
  //   subMenuItems: [
  //     {
  //       title: 'Realizar perfilamiento',
  //       url: '/perfilamiento'
  //     },
  //     {
  //       title: 'Continuar perfilamiento',
  //       url: '#'
  //     },
  //   ],
  // },
  {
    img: './assets/img/menu-crear.png',
    title: 'Consultas y reportes',
    info: 'Consulta o genera los reportes de la gestión de Libranzas',
    url: '',
    subMenuOpen: false,
    subMenuItems: [
      {
        title: 'Consulta por identificación',
        url: '/cases/cases-info',
      },
      {
        title: 'Reporte terceros con autorizaciones consulta y tratamiento datos',
        url: '/cases/repo',
      },
      // {
      //   title: 'Consulta seguridad social',
      //   url: '/cases/security',
      // }
    ],
  },
  {
    id:'action_12000',
    img: './assets/img/icon_reasignacion.svg',
    title: 'Reasignación ventas prospecto',
    info: 'Cambia de asesores las solicitudes de libranzas',
    url: '/reassignment/case-reassignment',
    subMenuOpen: false,
    subMenuItems: [],
  },
  // {
  //   img: './assets/img/informes.png',
  //   title: 'Historial',
  //   info: 'Consulta el registro de tus actividades',
  //   url: '/reports/reports-info',
  //   subMenuOpen: false,
  //   subMenuItems: [],
  // },
  {
    img: './assets/img/cerrar-sesion.png',
    title: 'Cerrar sesión',
    info: '',
    url: '',
    subMenuOpen: false,
    subMenuItems: [],
  },
];
